import Box from '@mui/material/Box';
import CheckBox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { CancelButton, PrimaryButton } from 'components/shared/Button/ActionButtons';
import { useFormik } from 'formik';
import { useGlobalStore } from 'global-state/useStore';
import { UserRegister } from 'interfaces/models/UserRegister';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useLogin } from 'rq/hooks/authHook';
import * as Yup from 'yup';

import { FormControlLabel } from '@mui/material';
import AuthService from 'services/AuthService/AuthService';
import i18n, { availableTranslations } from '../../res/localization/i18n';

const RegisterPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { mutate: login } = useLogin();
	const { isSeba } = useGlobalStore((state) => state.hostname);

	const validationSchema = Yup.object().shape({
		username: Yup.string().required(t('USERNAME_REQUIRED')),
		email: Yup.string().email(t('INVALID_EMAIL')).nullable(),
		// .required(t('EMAIL_REQUIRED'))
		password1: Yup.string()
			.required(t('PASSWORD_REQUIRED'))
			.min(6, t('PASSWORD_MIN_LENGTH_WARNING'))
			.max(50, t('PASSWORD_MAX_LENGTH_WARNING')),
		password2: Yup.string()
			.oneOf([Yup.ref('password1'), null], t('PASSWORDS_DO_NOT_MATCH'))
			.required(t('REQUIRED')),
		accepted_tac: Yup.boolean().oneOf([true]),
		legal_age: Yup.boolean().oneOf([true])
	});

	const formik = useFormik({
		initialValues: {
			username: '',
			email: '',
			password1: '',
			password2: '',
			first_name: '',
			last_name: '',
			display_language: (localStorage.getItem('lng') as UserRegister.display_language) ?? 'en',
			accepted_tac: false,
			legal_age: false,
			newsletter: false
		},
		validationSchema: validationSchema,
		onSubmit: (data) => {
			AuthService.authRegistrationCreate({
				...data,
				email: data.email === '' ? undefined : data.email
			})
				.then(() => {
					login({ username: data.username, password: data.password1, grant_type: 'password' });
				})
				.catch((error) => {
					const errors = error?.form_errors;
					formik.setErrors(errors);
				});
		}
	});

	const handleLanguageChange = (event: SelectChangeEvent) => {
		i18n.changeLanguage(event.target.value);
		localStorage.setItem('lng', event.target.value);
		formik.setFieldValue('display_language', event.target.value);
	};

	const checkboxTextClick = (type: string) => {
		type ObjectKey = keyof typeof formik.values;
		const myKey = `${type}` as ObjectKey;
		formik.setFieldValue(type, !formik.values[myKey]);
	};

	return (
		<Grid
			className="flex h-full w-full flex-col justify-center"
			item
			xs={12}
			sm={8}
			md={5}
			component={Paper}
			elevation={6}
			square>
			<Box
				className="max-h-full overflow-y-auto"
				sx={{
					px: 4,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center'
				}}>
				<img
					src={
						isSeba
							? '/assets/seba-icon.png'
							: 'https://discharge.ch/images/discharge_logo_mobile.png'
					}
					className={isSeba ? 'm-4 h-16 w-12' : 'm-4 h-16 w-16'}
					alt={isSeba ? 'Seba logo' : 'Discharge logo'}
				/>

				<Typography component="h1" variant="h5">
					{t('REGISTER')}
				</Typography>
				<form className="mt-1 w-full" onSubmit={formik.handleSubmit} autoComplete="off">
					<TextField
						margin="normal"
						required
						fullWidth
						id="username"
						label={t('USERNAME')}
						name="username"
						autoComplete="username"
						autoFocus
						value={formik.values.username}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						error={formik.touched.username && Boolean(formik.errors.username)}
						helperText={formik.touched.username && formik.errors.username}
					/>
					<div className="flex w-full flex-col md:flex-row md:justify-between md:gap-sm">
						<TextField
							margin="normal"
							fullWidth
							id="first_name"
							label={t('FIRST_NAME')}
							name="first_name"
							autoComplete="first_name"
							value={formik.values.first_name}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={formik.touched.first_name && Boolean(formik.errors.first_name)}
							helperText={formik.touched.first_name && formik.errors.first_name}
						/>
						<TextField
							margin="normal"
							fullWidth
							id="last_name"
							label={t('LAST_NAME')}
							name="last_name"
							autoComplete="last_name"
							value={formik.values.last_name}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={formik.touched.last_name && Boolean(formik.errors.last_name)}
							helperText={formik.touched.last_name && formik.errors.last_name}
						/>
					</div>
					<TextField
						margin="normal"
						fullWidth
						id="email"
						label={t('EMAIL')}
						name="email"
						autoComplete="email"
						value={formik.values.email}
						onBlur={formik.handleBlur}
						onChange={formik.handleChange}
						error={formik.touched.email && Boolean(formik.errors.email)}
						helperText={formik.touched.email && formik.errors.email}
					/>
					<TextField
						margin="normal"
						type="password"
						required
						fullWidth
						id="password1"
						label={t('PASSWORD')}
						name="password1"
						autoComplete="password1"
						value={formik.values.password1}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						error={formik.touched.password1 && Boolean(formik.errors.password1)}
						helperText={formik.touched.password1 && formik.errors.password1}
					/>
					<TextField
						margin="normal"
						type="password"
						required
						fullWidth
						id="password2"
						label={t('REPEAT_PASSWORD')}
						name="password2"
						autoComplete="password2"
						value={formik.values.password2}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						error={formik.touched.password2 && Boolean(formik.errors.password2)}
						helperText={formik.touched.password2 && formik.errors.password2}
					/>

					<div className="flex flex-col md:flex-row md:justify-between">
						<FormControl required sx={{ mt: 2, mb: 1, minWidth: 200 }}>
							<InputLabel id="demo-simple-select-required-label">
								{t('DISPLAY_LANGUAGE')}
							</InputLabel>
							<Select
								labelId="demo-simple-select-required-label"
								id="demo-simple-select-required"
								value={i18n.language}
								label={t('DISPLAY_LANGUAGE')}
								name="display_language"
								onChange={handleLanguageChange}>
								{availableTranslations.map((option) => {
									return (
										<MenuItem key={option.language} value={option.short}>
											<div className="mr-2 inline-block">
												<img
													src={`https://flagcdn.com/w20/${option.flag.toLowerCase()}.png`}
													alt="flag-image"
												/>
											</div>
											{t(option.language)}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</div>
					<div className="mt-2 text-sm md:mt-4">
						<div className="mb-2 flex flex-row justify-start self-start">
							<div>
								<FormControlLabel
									control={
										<CheckBox
											className="mr-4 p-0 text-secondary"
											name="newsletter"
											checked={formik.values.newsletter}
											onChange={formik.handleChange}
										/>
									}
									label={t('NEWSLETTER_AGREEMENT')}
								/>
							</div>
							<div>
								<div
									className="cursor-pointer self-center"
									onClick={checkboxTextClick.bind(this, 'newsletter')}
									dangerouslySetInnerHTML={{ __html: t('NEWSLETTER_AGREEMENT') }}></div>
								<div className="h-4 text-xs text-red-600">
									{formik.errors.newsletter && t('REQUIRED')}
								</div>
							</div>
						</div>
						<div className="mb-2 flex flex-row justify-start self-start">
							<div>
								<FormControlLabel
									control={
										<CheckBox
											className="mr-4 p-0 text-secondary"
											name="accepted_tac"
											checked={formik.values.accepted_tac}
											onChange={formik.handleChange}
										/>
									}
									label={t('ALLOW_STORE_AND_PROCESS')}
								/>
							</div>
							<div>
								<div
									className="cursor-pointer self-center"
									onClick={checkboxTextClick.bind(this, 'accepted_tac')}
									dangerouslySetInnerHTML={{ __html: t('ALLOW_STORE_AND_PROCESS') }}></div>
								<div className="h-4 text-xs text-red-600">
									{formik.errors.accepted_tac && t('REQUIRED')}
								</div>
							</div>
						</div>
						<div className="mb-2 flex flex-row justify-start self-start">
							<div>
								<FormControlLabel
									control={
										<CheckBox
											className="mr-4 p-0 text-secondary"
											name="legal_age"
											checked={formik.values.legal_age}
											onChange={formik.handleChange}
										/>
									}
									label={t('AGE_CONFIRMATION', { value: 16 })}
								/>
							</div>
							<div>
								<div
									className="cursor-pointer self-center"
									onClick={checkboxTextClick.bind(this, 'legal_age')}>
									<strong>{t('AGE_CONFIRMATION', { value: 16 })}</strong>
								</div>
								<div className="h-4 text-xs text-red-600">
									{formik.errors.legal_age && t('REQUIRED')}
								</div>
							</div>
						</div>
					</div>
					<div className="mb-2 mt-2 flex flex-row justify-center gap-md  md:mt-4">
						<PrimaryButton
							disabled={!formik.isValid || !formik.dirty}
							type="submit"
							text={t('REGISTER')}
						/>
						<CancelButton onClick={() => navigate('/')} text={t('CANCEL')} />
					</div>
				</form>
			</Box>
		</Grid>
	);
};

export default RegisterPage;
