import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import GCPCoordinates from 'components/EditGCPs/GCPCoordinates';
import GCPMeasurements from 'components/EditGCPs/GCPMeasurements';
import Modal from 'components/shared/Modal/Modal';
import { TabPanel } from 'components/shared/TabPanel/TabPanel';
import { useGlobalStore } from 'global-state/useStore';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const EditGCP = () => {
	const { t } = useTranslation();

	const { open } = useGlobalStore((state) => state.editGCPModal);
	const toggleEditGCPModal = useGlobalStore((state) => state.toggleEditGCPModal);
	const [tabValue, setTabValue] = useState<number>(0);
	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setTabValue(newValue);
	};
	const handleClose = () => {
		toggleEditGCPModal({ open: false, station_id: null, stationDetails: null });
	};

	return (
		<Modal modalTitle={t('MARKERS')} open={open} closeModal={handleClose}>
			<div className={'flex max-w-3xl flex-1 flex-col'}>
				<Tabs
					className=" w-full max-w-3xl"
					variant="scrollable"
					allowScrollButtonsMobile
					value={tabValue}
					onChange={handleTabChange}>
					<Tab label={t('MEASUREMENTS')} />
					<Tab label={t('COORDINATES')} />
				</Tabs>
				<div className="min-h-[40rem] w-full max-w-3xl border border-solid border-[#bfbfbf] bg-white">
					<TabPanel value={tabValue} index={0}>
						<div className="p-4">
							<GCPMeasurements />
						</div>
					</TabPanel>
					<TabPanel value={tabValue} index={1}>
						<div className="flex flex-col p-4">
							<GCPCoordinates />
						</div>
					</TabPanel>
				</div>
			</div>
		</Modal>
	);
};
export default EditGCP;
