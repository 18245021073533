import Layout from 'components/shared/Layout/Layout';
import CircularLoadingFullHeight from 'components/shared/LoadingIndicators/CircularLoadingFullHeight';
import { useGlobalStore } from 'global-state/useStore';
import AuthPagesTheme from 'pages/Auth/AuthPagesTheme';
import JoinOrganizations from 'pages/Auth/JoinOrganizations';
import { useEffect } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { useAuth } from 'rq/hooks/authHook';
import { timezone_display } from 'rq/interfaces/User';
import { organizationRoutes } from './organizationRoutes';

const PrivateRoutesWrapper = () => {
	const setUserInfo = useGlobalStore((state) => state.setUserInfo);
	const { data: isAuth, isLoading } = useAuth();

	useEffect(() => {
		if (isAuth) {
			setUserInfo({ timezone_display: isAuth.timezone_display ?? timezone_display.LOCAL });
		}
	}, [isAuth]);

	if (isLoading) {
		return <CircularLoadingFullHeight />;
	}

	const hasOrganizations = isAuth?.organizations && isAuth?.organizations.length > 0;

	if (!hasOrganizations) {
		return (
			<AuthPagesTheme>
				<Routes>
					<Route path="/join-organizations" element={<JoinOrganizations />} />
					<Route path="*" element={<Navigate to="/join-organizations" replace />} />
				</Routes>
			</AuthPagesTheme>
		);
	}

	return (
		<Layout>
			<Outlet />
		</Layout>
	);
};

export const privateRoutes = [
	{
		element: <PrivateRoutesWrapper />,
		children: [
			{
				path: '/',
				children: [...organizationRoutes]
			}
		]
	}
];
