import { apiClient } from 'agent/apiClient';
import { useGlobalStore } from 'global-state/useStore';
import { AuthToken, AuthTokenResponse } from 'interfaces/models/AuthToken';
import i18n from 'res/localization/i18n';
import { queryKeys } from 'rq/constants';
import { UserListRetrieve } from 'rq/interfaces/User';
import { queryClient } from 'rq/queryClient';
import { checkOrgIdAndPermissions } from 'utils/functions';
import { CheckTokenData, IAuthService } from './interface';

const AuthService: IAuthService = {
	getAuth: async () => {
		if (window.localStorage.getItem('access_token')) {
			const { data } = await apiClient.get<UserListRetrieve | null>('auth/user/');
			return data;
		} else return null;
	},

	login: async (data) => {
		const formData = new FormData();
		formData.append('client_id', process.env.REACT_APP_CLIENT_ID as string);
		formData.append('grant_type', data.grant_type ?? '');
		if (data.grant_type === 'password') {
			formData.append('username', data.username);
			formData.append('password', data.password);
		} else {
			const refresh_token = localStorage.getItem('refresh_token') as string;
			formData.append('refresh_token', refresh_token);
		}

		return apiClient
			.post(`auth/token/`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then((res) => {
				localStorage.setItem('access_token', res.data.access_token);
				localStorage.setItem('refresh_token', res.data.refresh_token);
				const expirationDate = new Date(new Date().getTime() + res.data.expires_in * 1000);
				localStorage.setItem('expirationDate', expirationDate.toString());

				queryClient.invalidateQueries(queryKeys.me);

				return res.data;
			})
			.catch((error) => {
				return error;
			});
	},

	logout: async () => {
		AuthService.authApplicationTokenRevoke(localStorage.getItem('access_token') as string);
		localStorage.removeItem('username');
		localStorage.removeItem('access_token');
		localStorage.removeItem('refresh_token');
		localStorage.removeItem('expirationDate');
		queryClient.setQueryData(queryKeys.me, null);
		queryClient.clear();
		return Promise.resolve();
	},
	checkToken: async () => {
		const tokenValid = await AuthService.checkAuth();
		if (tokenValid) {
			AuthService.checkAuthTimeout();
			const user = queryClient.getQueryData(queryKeys.me) as UserListRetrieve;
			user &&
				useGlobalStore.setState({ currentOrganization: checkOrgIdAndPermissions(user, user.id) });
			if (user) {
				await Promise.resolve();
			}
		} else {
			const refresh_token = localStorage.getItem('refresh_token') as string;
			if (refresh_token) {
				const user = (await AuthService.login({
					username: '',
					password: '',
					grant_type: 'refresh_token'
				})) as UserListRetrieve;
				user &&
					useGlobalStore.setState({ currentOrganization: checkOrgIdAndPermissions(user, user.id) });
				await Promise.resolve();
			} else {
				await AuthService.logout();
			}
		}
	},
	refreshToken: async () => {
		try {
			const formData = new FormData();
			formData.append('client_id', process.env.REACT_APP_CLIENT_ID as string);
			formData.append('grant_type', 'refresh_token');
			formData.append('refresh_token', localStorage.getItem('refresh_token') as string);

			const response = await apiClient.post('auth/token/', formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});

			localStorage.setItem('access_token', response.data.access_token);
			localStorage.setItem('refresh_token', response.data.refresh_token);
			localStorage.setItem(
				'expirationDate',
				new Date(Date.now() + response.data.expires_in * 1000).toString()
			);

			return response.data;
		} catch (error) {
			await AuthService.logout();
			throw error;
		}
	},
	checkAuth: async () => {
		try {
			const token = localStorage.getItem('access_token');
			const refreshToken = localStorage.getItem('refresh_token');
			const expirationDate = localStorage.getItem('expirationDate');

			if (!token || !refreshToken || !expirationDate) {
				return false;
			}

			const expiration = new Date(Date.parse(expirationDate));
			const now = new Date();

			// If token is expired but we have refresh token
			if (expiration.getTime() < now.getTime() && refreshToken) {
				try {
					await AuthService.refreshToken();
					return true;
				} catch {
					return false;
				}
			}

			// If token exists and is valid
			return await AuthService.authTokenCheckCreate({ token });
		} catch {
			return false;
		}
	},
	getPermissions: () => Promise.reject('Unknown method'),
	getIdentity: async () => {
		return await AuthService.getAuth().then((response) => {
			Promise.resolve();
			return response as UserListRetrieve;
		});
	},

	authTokenCheckCreate: async (data: AuthToken) => {
		return await apiClient
			.post<AuthTokenResponse>('/auth/token/check/', { token: data.token })
			.then((response) => {
				if (
					response.data.message === 'Token is valid.' ||
					response.data.details === 'Carry on.' ||
					response.data.code === 'TKN_OK'
				) {
					Promise.resolve();
					return true;
				} else {
					Promise.reject(i18n.t('INVALID_TOKEN'));
					return false;
				}
			})
			.catch((error) => {
				Promise.reject(error);
				return false;
			});
	},
	checkAuthTimeout: () => {
		const expirationDate = localStorage.getItem('expirationDate') as string;
		const date = new Date(Date.parse(expirationDate));
		const timer = (date.getTime() - new Date().getTime()) / 1000; // timer in seconds

		//setTimeout has a limit of approximately 24.8 days, and our expiration time is cca 30 days, so following logic is needed

		const numberOfDays = timer / 60 / 60 / 24; // timer is in seconds
		if (numberOfDays <= 15) {
			setTimeout(() => {
				AuthService.refreshToken();
			}, timer * 1000);
		} else {
			setTimeout(() => {
				AuthService.checkAuthTimeout();
			}, 16 * 24 * 60 * 60 * 1000); // 16 days in ms
		}
	},
	authPasswordChangeCreate: async (data) => {
		return await apiClient.post('/auth/password/change/', data).then((res) => {
			Promise.resolve();
			return res.data;
		});
	},
	authRegistrationCreate: async (data) => {
		return await apiClient.post('/auth/registration/', data).then((res) => {
			Promise.resolve();
			return res.data;
		});
	},
	authPasswordResetConfirmCreate: async (data) => {
		return await apiClient.post('/auth/password/reset/confirm/', data).then((res) => {
			Promise.resolve();
			return res.data;
		});
	},
	authPasswordResetCreate: async (data) => {
		return await apiClient.post('/auth/password/reset/', data).then((res) => {
			Promise.resolve();
			return res.data;
		});
	},
	authPasswordCheckCreate: async (data) => {
		return await apiClient.post('/auth/password/check/', data).then((res) => {
			Promise.resolve();
			return res.data;
		});
	},
	authApplicationTokenCreate: async () => {
		return await apiClient
			.post('/auth/application-token/', {
				client_id: `${process.env.REACT_APP_APP_TOKEN_CLIENT_ID}`
			})
			.then((res) => {
				Promise.resolve();
				return res.data;
			});
	},
	authApplicationTokenRevoke: async (application_token) => {
		const formData = new FormData();
		formData.append('client_id', process.env.REACT_APP_CLIENT_ID as string);
		formData.append('token', application_token);
		return await apiClient
			.post('/auth/revoke-token/', formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then((res) => {
				Promise.resolve();
				return res.data;
			});
	},
	encodeToken: async () => {
		return await apiClient.get('cloudprocessing/url-param/').then((res) => {
			return res.data;
		});
	},
	checkEncodedToken: async (data: CheckTokenData) => {
		return await apiClient.post(`cloudprocessing/url-param/fetch/`, data).then((response) => {
			window.localStorage.removeItem('access_token');
			window.localStorage.removeItem('refresh_token');
			window.localStorage.removeItem('expirationDate');
			window.localStorage.setItem('access_token', response.data.token);
			return response.data;
		});
	}
};

export default AuthService;
