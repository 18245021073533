import { Chat } from '@mui/icons-material';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import classNames from 'classnames';
import { useGlobalStore } from 'global-state/useStore';
import { useTranslation } from 'react-i18next';
import { MainListItems } from './MenuItems';

const drawerWidth = 200;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
	({ theme, open }) => ({
		'& .MuiDrawer-paper': {
			[theme.breakpoints.down(768)]: {
				width: open ? '100vw' : 0
			},
			position: 'relative',
			zIndex: 10,
			whiteSpace: 'nowrap',
			color: 'white',
			width: drawerWidth,
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen
			}),
			boxSizing: 'border-box',
			overflowX: 'hidden',
			...(!open && {
				overflowX: 'hidden',
				transition: theme.transitions.create('width', {
					easing: theme.transitions.easing.sharp,
					duration: theme.transitions.duration.leavingScreen
				}),
				width: '3.5rem'
			})
		}
	})
);

const NavBar = ({ open }: { open: boolean }) => {
	const { t } = useTranslation();
	const { isSeba } = useGlobalStore((state) => state.hostname);
	const feedbackLinkOptions = {
		emailTo: 'discharge@photrack.ch',
		emailCc: 'info@encode.hr',
		emailSub: 'Discharge Feedback'
	};
	return (
		<>
			<Drawer variant="permanent" className={'h-full max-h-[100vh] overflow-hidden'} open={open}>
				<List
					component="nav"
					className={
						'flex h-full max-h-[100vh] flex-col justify-between overflow-hidden bg-secondary'
					}>
					<MainListItems />
					<div className="flex flex-col">
						<div className="min-h-[48px] md:h-[48px]">
							<ListItemButton
								className={'h-[48px] bg-secondary'}
								onClick={(e) => {
									e.preventDefault();
									window.location.href =
										'mailto:' +
										feedbackLinkOptions.emailTo +
										'?cc=' +
										feedbackLinkOptions.emailCc +
										'&subject=' +
										feedbackLinkOptions.emailSub;
								}}>
								<ListItemIcon style={{ minWidth: '2.5rem' }}>
									<Chat className={'text-primary'} />
								</ListItemIcon>
								<ListItemText primary={t('FEEDBACK')} className="min-w-[150px]" />
							</ListItemButton>
						</div>
						<div
							className={classNames({
								'flex h-[120px] items-center justify-center px-2 md:h-20 md:min-h-[56px]': true,
								'h-[280px]': isSeba
							})}>
							{isSeba && open && (
								<img
									src={'/assets/seba-vector-logo.svg'}
									alt={'Seba logo'}
									className="max-w-full"
								/>
							)}
							{isSeba && !open && (
								<img
									src={'/assets/seba-vector-icon.svg'}
									className={'ml-1 max-h-40'}
									alt={'Seba logo'}></img>
							)}
							{!isSeba && open && (
								<img
									src={'/assets/Discharge_logotip_menu.svg'}
									className={'max-w-full cursor-pointer object-contain'}
									onClick={() => {
										window.open(
											'https://www.photrack.ch/dischargeapp.html',
											'_blank',
											'noreferrer,noopener'
										);
									}}
									alt={'Discharge logo'}
								/>
							)}
							{!isSeba && !open && (
								<img
									src={'/assets/Discharge_icon.png'}
									alt={'Discharge logo'}
									onClick={() => {
										window.open(
											'https://www.photrack.ch/dischargeapp.html',
											'_blank',
											'noreferrer,noopener'
										);
									}}
									className={'h-10 w-10 cursor-pointer'}></img>
							)}
						</div>
					</div>
				</List>
			</Drawer>
		</>
	);
};

export default NavBar;
