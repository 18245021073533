import { MyLocation, PushPin } from '@mui/icons-material';
import LocationIcon from '@mui/icons-material/LocationOn';

import TextField from '@mui/material/TextField';
import { PrimaryButton } from 'components/shared/Button/ActionButtons';
import Modal from 'components/shared/Modal/Modal';
import { useFormik } from 'formik';
import { useGlobalStore } from 'global-state/useStore';
import { Icon, PointExpression } from 'leaflet';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MapContainer, Marker, TileLayer, useMapEvents } from 'react-leaflet';
import { useNavigate } from 'react-router-dom';
import { queryKeys } from 'rq/constants';
import { useCreateSite } from 'rq/hooks/sitesHook';
import { queryClient } from 'rq/queryClient';
import { CreateSiteParams } from 'services/SitesService/interface';
import * as Yup from 'yup';
import { getCoordinatesByCountryCode } from '../../../helper/helperFunctions';
import { useOrganizationDetails } from '../../../rq/hooks/organizationsHook';

const CreateNewSiteModal = () => {
	const navigate = useNavigate();

	const createNewSiteModal = useGlobalStore((state) => state.createNewSiteModal);
	const toggleCreateNewSiteModal = useGlobalStore((state) => state.toggleCreateNewSiteModal);
	const currentOrganization = useGlobalStore((state) => state.currentOrganization);
	const toggleConfirmationDialog = useGlobalStore((state) => state.toggleConfirmationDialog);

	const { mutate: createSite } = useCreateSite();

	const { t } = useTranslation();

	const mapRef = useRef<L.Map>(null);
	const { data: org } = useOrganizationDetails(
		localStorage.getItem('currentOrganizationId') as string
	);

	const [initialCoordinates, setInitialCoordinates] = useState({ latitude: 0, longitude: 0 });

	useEffect(() => {
		const fetchCoordinates = async () => {
			const coordinates = await getCoordinatesByCountryCode(org?.country ?? 'CH');
			setInitialCoordinates(coordinates);
		};
		fetchCoordinates();
	}, [org?.country]);

	const validationSchema = Yup.object().shape({
		name: Yup.string().required(t('NAME_REQUIRED')),
		latitude: Yup.number().required(t('REQUIRED')),
		longitude: Yup.number().required(t('REQUIRED'))
	});

	const formik = useFormik({
		initialValues: {
			name: '',
			latitude: initialCoordinates.latitude,
			longitude: initialCoordinates.longitude
		},
		validationSchema: validationSchema,
		onSubmit: (data) => {
			handleCreateSite(data);
		},
		enableReinitialize: true
	});

	const handleCreateSite = (data: CreateSiteParams) => {
		createSite(
			{ name: data.name, latitude: data.latitude, longitude: data.longitude },
			{
				onSuccess: (res) => {
					toggleCreateNewSiteModal();
					navigate(`/${currentOrganization.id}/sites/${res.site_id}`);
					queryClient.invalidateQueries(queryKeys.sites_paginated);
				}
			}
		);
	};

	const handleCloseModal = () => {
		if (formik.dirty) {
			toggleConfirmationDialog({
				dialogOpen: true,
				headerTitle: t('UNSAVED_CHANGES_TITLE'),
				message: t('UNSAVED_CHANGES'),
				confirmAction: () => {
					toggleCreateNewSiteModal();
				},
				type: 'confirmation'
			});
		} else {
			toggleCreateNewSiteModal();
		}
	};

	useEffect(() => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const { latitude, longitude } = position.coords;
					formik.setFieldValue('latitude', latitude);
					formik.setFieldValue('longitude', longitude);
				},
				(error) => {
					console.error('Error Code = ' + error.code + ' - ' + error.message);
				}
			);
		}
	}, []);

	const LocationFinder = () => {
		useMapEvents({
			click: async (e) => {
				// Update longitude and wait for it to complete
				await new Promise((resolve) => {
					formik.setFieldValue('longitude', e.latlng.lng, true);
					resolve(true);
				});

				// After longitude is set and validated, update latitude
				await new Promise((resolve) => {
					formik.setFieldValue('latitude', e.latlng.lat, true);
					resolve(true);
				});
			}
		});
		return null;
	};

	const markerIcon = new Icon({
		iconUrl: '/assets/Map_marker.svg',
		iconSize: [40, 40] as PointExpression
	});

	useEffect(() => {
		!isNaN(formik.values.latitude) &&
			!isNaN(formik.values.longitude) &&
			mapRef.current?.flyTo(
				[formik.values.latitude, formik.values.longitude],
				mapRef.current?.getZoom(),
				{
					duration: 0.1
				}
			);
	}, [formik.values.latitude, formik.values.longitude]);

	return (
		<>
			<Modal
				open={createNewSiteModal.open}
				modalTitle={t('NEW_SITE')}
				closeModal={handleCloseModal}>
				<form className="w-full" onSubmit={formik.handleSubmit} autoComplete="off">
					<div className={'flex w-full flex-col gap-md py-4 md:w-[50rem]'}>
						<div className={'flex w-full items-center justify-center gap-xs align-middle'}>
							<LocationIcon fontSize={'large'}></LocationIcon>
							<TextField
								className={'text-xl font-bold'}
								label={t('SITE_NAME')}
								variant={'standard'}
								InputProps={{
									style: { fontSize: 'x-large', color: 'black' }
								}}
								InputLabelProps={{
									style: { fontSize: 'x-large' }
								}}
								value={formik.values.name}
								name={'name'}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								error={formik.touched.name && Boolean(formik.errors.name)}
							/>
						</div>
						<div className={'flex flex-col'}>
							<div className={'z-50 h-80 w-full rounded-t-md '}>
								<MapContainer
									center={[formik.values.latitude, formik.values.longitude]}
									className={'rounded-t-md'}
									zoom={15}
									scrollWheelZoom={false}
									bounceAtZoomLimits={true}
									zoomControl={true}
									minZoom={3}
									ref={mapRef}>
									<TileLayer
										attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
										url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
										maxZoom={25}
										className={'rounded-t-md'}
										maxNativeZoom={19}
									/>
									<>
										<LocationFinder></LocationFinder>
										<Marker
											position={[formik.values.latitude, formik.values.longitude]}
											icon={markerIcon}></Marker>
									</>
								</MapContainer>
							</div>
							<div className={'my-4 mr-2 flex flex-col items-center gap-md'}>
								<div className={'flex items-center gap-sm align-middle'}>
									<LocationIcon></LocationIcon>
									<TextField
										label={t('LATITUDE')}
										size={'small'}
										type={'number'}
										value={formik.values.latitude}
										name={'latitude'}
										className={'w-32 md:w-40'}
										onChange={(e) => {
											formik.handleChange(e);
										}}
										onBlur={formik.handleBlur}
										error={formik.touched.latitude && Boolean(formik.errors.latitude)}
									/>
									<TextField
										label={t('LONGITUDE')}
										size={'small'}
										value={formik.values.longitude}
										name={'longitude'}
										type={'number'}
										className={'w-32 md:w-40'}
										onChange={(e) => {
											formik.handleChange(e);
										}}
										onBlur={formik.handleBlur}
										error={formik.touched.longitude && Boolean(formik.errors.longitude)}
									/>
								</div>
								<div className={'flex gap-md xl:flex-col'}>
									<PrimaryButton
										className={'max-w-xs'}
										onClick={() => {
											mapRef.current?.locate().on('locationfound', function (e) {
												formik.setFieldValue('latitude', e.latlng.lat);
												formik.setFieldValue('longitude', e.latlng.lng);
											});
										}}>
										<div className={'flex gap-sm'}>
											<MyLocation />
											{t('USE_MY_LOCATION')}
										</div>
									</PrimaryButton>
									<PrimaryButton className={'max-w-xs'}>
										<div className={'flex gap-sm'}>
											<PushPin /> {t('SELECT_LOCATION_ON_MAP')}
										</div>
									</PrimaryButton>
								</div>
							</div>
						</div>
						<div className={'flex h-10 w-full justify-center'}>
							<PrimaryButton
								className={'max-w-xs'}
								disabled={!formik.isValid || !formik.dirty}
								type={'submit'}>
								<div className={'flex gap-sm'}>{t('CREATE')}</div>
							</PrimaryButton>
						</div>
					</div>
				</form>
			</Modal>
		</>
	);
};
export default CreateNewSiteModal;
