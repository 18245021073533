import { PrimaryButton } from 'components/shared/Button/ActionButtons';
import { useGlobalStore } from 'global-state/useStore';
import { DischargeStationDetails } from 'interfaces/models/DischargeStationDetails';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useStationDetails, useUpdateStation } from 'rq/hooks/stationHook';

const CrossSectionPreview = () => {
	const { t } = useTranslation();
	const { base64Img, markers, profile } = useGlobalStore((state) => state.profileCalibration);
	const toggleSetUpCrossSectionModal = useGlobalStore(
		(state) => state.toggleSetUpCrossSectionModal
	);

	const setUpCrossSectionModal = useGlobalStore((state) => state.setUpCrossSectionModal);
	const setCalibrationImg = useGlobalStore((state) => state.setCalibrationImg);
	const selectedSiteId = useGlobalStore((state) => state.selectedSiteId);

	const { mutate: updateStation } = useUpdateStation();
	const { data: stationDetails } = useStationDetails(
		Number(selectedSiteId),
		Number(setUpCrossSectionModal.station_id)
	);

	const handleCrossSectionUpdate = () => {
		const data = { ...(stationDetails as DischargeStationDetails) };
		if (markers && markers.length > 0 && profile && profile.length > 0) {
			data.markers_coordinates = markers;
			data.profile.free_params = profile;
		}

		updateStation(
			{
				siteId: Number(selectedSiteId),
				stationId: Number(setUpCrossSectionModal.station_id),
				data: data
			},
			{
				onSuccess: () => {
					toast.success(t('STATION_UPDATED'));
					toggleSetUpCrossSectionModal({ open: false, station_id: null });
					setCalibrationImg('', [], []);
				},
				onError: (err: any) => {
					toast.error(err.details);
				}
			}
		);
	};

	return (
		<div className={'flex flex-1 flex-col gap-md '}>
			<img src={`data:image/png;base64,${base64Img}`} alt={t('CROSS_SECTION')} />
			<div className={'self-center pb-4'}>
				<PrimaryButton onClick={handleCrossSectionUpdate}>{t('SAVE')}</PrimaryButton>
			</div>
		</div>
	);
};
export default CrossSectionPreview;
