import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from 'rq/hooks/authHook';

export const ProtectedRoute = ({ isAllowed, children }: { isAllowed: boolean; children: any }) => {
	if (!isAllowed) {
		return <Navigate to="/" replace />;
	}

	return children;
};

export const ProtectedRouteWithSuperAdmin = ({ children }: { children: React.ReactNode }) => {
	const { data: user } = useAuth();
	if (!user?.super_admin) {
		return <Navigate to="/" replace />;
	}

	return children;
};
