import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import './res/localization/i18n';

import * as Sentry from '@sentry/react';

import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { RouterProvider } from 'react-router-dom';
import { queryClient } from 'rq/queryClient';

import { arSD, deDE, enUS, esES, frFR, itIT, ruRU, zhCN } from '@mui/material/locale';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useTranslation } from 'react-i18next';
import routes from 'routing/routes';

export function LocalizedThemeProvider({ children }: { children: React.ReactNode }) {
	const { i18n } = useTranslation();

	// Map your i18n language codes to MUI locale objects
	const localeMap = {
		en: enUS,
		fr: frFR,
		de: deDE,
		es: esES,
		ru: ruRU,
		ch: zhCN,
		it: itIT,
		ar: arSD
	};

	const theme = createTheme(
		{
			palette: {
				primary: {
					main: '#067BC2'
				},
				secondary: {
					main: '#F4F2F3'
				},
				error: { main: '#DC602E' }
			}
		},
		localeMap[i18n.language as keyof typeof localeMap] || enUS // fallback to English if language not found
	);

	return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

Sentry.init({
	dsn: 'https://7186b6ee81f54d7f9fde0b20967a5bf9@o155943.ingest.sentry.io/4505001608740864',
	environment: process.env.NODE_ENV,
	integrations: [new Sentry.BrowserTracing()],
	tracesSampleRate: 1.0,
	enabled: process.env.NODE_ENV !== 'development'
});

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<React.StrictMode>
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<QueryClientProvider client={queryClient}>
				<div id={'leafletmap'} />
				<LocalizedThemeProvider>
					<RouterProvider router={routes} />
				</LocalizedThemeProvider>
				<ReactQueryDevtools initialIsOpen={false} />
			</QueryClientProvider>
		</LocalizationProvider>
	</React.StrictMode>
);

reportWebVitals();
