import React from 'react';
import { ProtectedRouteWithSuperAdmin } from 'routing/ProtectedRoute';
import { LazyComponentWrapper } from 'routing/RouteWrappers';

const CloudProcessing = React.lazy(() => import('pages/CloudProcessing/CloudProcessing'));
const CloudProcessingHome = React.lazy(() => import('pages/CloudProcessing/CloudProcessingHome'));

export const cloudProcessingRoutes = [
	{
		path: 'cloudprocessing',
		element: (
			<ProtectedRouteWithSuperAdmin>
				<LazyComponentWrapper>
					<CloudProcessingHome />
				</LazyComponentWrapper>
			</ProtectedRouteWithSuperAdmin>
		)
	},
	{
		path: 'cloudprocessing/:siteId',
		element: (
			<ProtectedRouteWithSuperAdmin>
				<LazyComponentWrapper>
					<CloudProcessing />
				</LazyComponentWrapper>
			</ProtectedRouteWithSuperAdmin>
		)
	},
	{
		path: 'cloudprocessing/:siteId/:stationId/:measurementId',
		element: (
			<ProtectedRouteWithSuperAdmin>
				<LazyComponentWrapper>
					<CloudProcessing />
				</LazyComponentWrapper>
			</ProtectedRouteWithSuperAdmin>
		)
	}
];
