import CircularLoadingFullHeight from 'components/shared/LoadingIndicators/CircularLoadingFullHeight';
import { useGlobalStore } from 'global-state/useStore';
import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { LazyComponentWrapper } from 'routing/RouteWrappers';
import { useAuth } from 'rq/hooks/authHook';
import { OrganizationListRetrieve } from 'rq/interfaces/Organization';
import { OrganizationsService } from 'services/OrganizationsService/OrganizationService';
import { cloudProcessingRoutes } from './cloudProcessingRoutes';

const OrganizationPage = React.lazy(() => import('pages/Organization/OrganizationPage'));
const ProfilePage = React.lazy(() => import('pages/ProfilePage/ProfilePage'));
const UsersPage = React.lazy(() => import('pages/Users/UsersPage'));
const Alarms = React.lazy(() => import('pages/Alarms/Alarms'));
const MapPage = React.lazy(() => import('pages/Map/MapPage'));
const SitesPage = React.lazy(() => import('pages/Sites/SitesPage'));
const SiteDetailsPage = React.lazy(() => import('pages/SiteDetails/SiteDetails'));
const MeasurementsPage = React.lazy(() => import('pages/MeasurementsPage/MeasurementsPage'));

//Organization wrapper to check if user has access to the organization
const OrganizationWrapper = ({ children }: { children: React.ReactNode }) => {
	const [loading, setLoading] = useState(true);
	const location = useLocation();
	const locationState = location.state as { orgName: string } | undefined;
	const params = useParams();
	const navigate = useNavigate();
	const toggleJoinOrRedirectOrganizationModal = useGlobalStore(
		(state) => state.toggleJoinOrRedirectOrganizationModal
	);
	const { data: user } = useAuth();

	useEffect(() => {
		const checkOrganizationAccess = async () => {
			setLoading(true);
			const localstorageOrgId = Number(localStorage.getItem('currentOrganizationId'));
			const paramId = Number(params.orgId);

			if (isNaN(paramId)) {
				navigate(`/${localstorageOrgId}`);
				return;
			}

			const hasAccess =
				user?.super_admin ||
				user?.organizations?.some((org: OrganizationListRetrieve) => org.id === paramId);

			if (hasAccess) {
				await OrganizationsService.switchOrganization(paramId);
			} else {
				toggleJoinOrRedirectOrganizationModal(true, paramId, locationState?.orgName || '', true);
			}
			setLoading(false);
		};

		checkOrganizationAccess();
	}, [params.orgId]);

	if (loading)
		return (
			<div className="h-screen w-screen">
				<CircularLoadingFullHeight />
			</div>
		);

	return <>{children}</>;
};

export const organizationRoutes = [
	{
		element: (
			<OrganizationWrapper>
				<Outlet />
			</OrganizationWrapper>
		),
		children: [
			{
				path: ':orgId',
				children: [
					{
						index: true,
						element: (
							<>
								<Navigate to="map" replace />
							</>
						)
					},
					{
						path: 'organization',
						element: (
							<LazyComponentWrapper>
								<OrganizationPage />
							</LazyComponentWrapper>
						)
					},
					{
						path: 'users',
						element: (
							<LazyComponentWrapper>
								<UsersPage />
							</LazyComponentWrapper>
						)
					},
					{
						path: 'users/:userId',
						element: (
							<LazyComponentWrapper>
								<ProfilePage />
							</LazyComponentWrapper>
						)
					},
					{
						path: 'alarms',
						element: (
							<LazyComponentWrapper>
								<Alarms />
							</LazyComponentWrapper>
						)
					},
					{
						path: 'map',
						element: (
							<LazyComponentWrapper>
								<MapPage />
							</LazyComponentWrapper>
						)
					},
					{
						path: 'sites',
						element: (
							<LazyComponentWrapper>
								<SitesPage isCloudProcessing={false} />
							</LazyComponentWrapper>
						)
					},
					{
						path: 'sites/:siteId',
						element: (
							<LazyComponentWrapper>
								<SiteDetailsPage />
							</LazyComponentWrapper>
						)
					},
					{
						path: 'measurements',
						element: (
							<LazyComponentWrapper>
								<MeasurementsPage />
							</LazyComponentWrapper>
						)
					},
					{
						path: 'measurements/:siteId/:stationId',
						element: (
							<LazyComponentWrapper>
								<MeasurementsPage />
							</LazyComponentWrapper>
						)
					},
					...cloudProcessingRoutes
				]
			}
		]
	}
];
