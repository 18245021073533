import SomethingWentWrong from 'components/Error/SomethingWentWrong';
import CircularLoadingFullHeight from 'components/shared/LoadingIndicators/CircularLoadingFullHeight';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

export const LazyComponentWrapper = ({ children }: { children: React.ReactNode }) => (
	<ErrorBoundary
		FallbackComponent={({ error, resetErrorBoundary }) => (
			<SomethingWentWrong error={{ error, resetErrorBoundary }} tryAgain={resetErrorBoundary} />
		)}>
		<Suspense fallback={<CircularLoadingFullHeight />}>{children}</Suspense>
	</ErrorBoundary>
);
