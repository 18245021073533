import LoginPage from 'pages/Auth/LoginPage';
import RegisterPage from 'pages/Auth/RegisterPage';
import ResetPassword from 'pages/Auth/ResetPassword';
import ResetPasswordConfirm from 'pages/Auth/ResetPasswordConfirm';

export const authRoutes = [
	{
		path: 'login',
		element: <LoginPage />
	},
	{
		path: 'register',
		element: <RegisterPage />
	},
	{
		path: 'password/reset',
		element: <ResetPassword />
	},
	{
		path: 'password/reset/confirm/:uid/:token',
		element: <ResetPasswordConfirm />
	}
];
