import { useGlobalStore } from 'global-state/useStore';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useBlocker } from 'react-router-dom';

interface BlockerState {
	isDirty: boolean;
	reset: () => void;
}

const NavigationBlocker = () => {
	const { t } = useTranslation();
	const toggleConfirmationDialog = useGlobalStore((state) => state.toggleConfirmationDialog);
	const formStates = useGlobalStore((state) => state.formStates);

	const hasUnsavedChanges = () => {
		return Array.from(formStates.values()).some((value) => value.isDirty);
	};

	const resetAllForms = () => {
		Object.values(formStates).forEach((state) => state.reset());
	};

	const blocker = useBlocker(({ currentLocation, nextLocation }) => {
		return hasUnsavedChanges() && currentLocation.pathname !== nextLocation.pathname;
	});

	useEffect(() => {
		if (blocker.state === 'blocked') {
			toggleConfirmationDialog({
				dialogOpen: true,
				headerTitle: t('UNSAVED_CHANGES_TITLE'),
				message: t('UNSAVED_CHANGES'),
				confirmAction: () => {
					blocker.proceed?.();
					resetAllForms();
				},
				cancelAction: () => {
					blocker.reset?.();
				},
				type: 'confirmation'
			});
		}
	}, [blocker.state]);

	return null;
};

export default NavigationBlocker;
