import { createBrowserRouter, Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from 'rq/hooks/authHook';
import App from '../App';
import CircularLoadingFullHeight from '../components/shared/LoadingIndicators/CircularLoadingFullHeight';
import AuthPagesTheme from '../pages/Auth/AuthPagesTheme';
import { authRoutes } from './routeConfigurations/authRoutes';
import { privateRoutes } from './routeConfigurations/privateRoutes';

// Auth guard component
const RequireAuth = () => {
	const { data: isAuth, isLoading } = useAuth();
	const location = useLocation();

	if (isLoading) {
		return (
			<div className="h-screen w-screen">
				<CircularLoadingFullHeight />
			</div>
		);
	}

	if (!isAuth) {
		return <Navigate to="/login" state={{ from: location }} replace />;
	}

	return <Outlet />;
};

const PublicRoute = () => {
	const { data: isAuth, isLoading } = useAuth();
	const location = useLocation();

	if (isLoading) {
		return (
			<div className="h-screen w-screen">
				<CircularLoadingFullHeight />
			</div>
		);
	}

	if (isAuth) {
		const from =
			location.state?.from?.pathname || `/${localStorage.getItem('currentOrganizationId')}`;
		return <Navigate to={from} replace />;
	}

	return <Outlet />;
};

const routes = createBrowserRouter([
	{
		path: '/',
		element: <App />,
		children: [
			{
				element: <PublicRoute />,
				children: [
					{
						index: true,
						element: <Navigate to="/map" replace />
					},
					{
						element: (
							<AuthPagesTheme>
								<Outlet />
							</AuthPagesTheme>
						),
						children: authRoutes
					}
				]
			},
			{
				element: <RequireAuth />,
				children: privateRoutes
			}
		]
	},
	{
		path: '*',
		element: <Navigate to="/login" replace />
	}
]);

export default routes;
